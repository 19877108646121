<template>
    <div class="w-full">
        <label for="" class="vs-input--label"
            ><span v-if="selected">Plan</span></label
        >
        <v-select
            class="w-full"
            v-model="selected"
            :options="plans"
            placeholder="Select Plan"
        ></v-select>
    </div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { http } from '@/services'

export default {
    name: 'BrokerPlanSelect',
    components: {
        vSelect,
    },
    props: ['brokerPlan'],
    watch: {
        selected(value) {
            this.$emit('selectedPlan', value)
        },
    },
    data() {
        return {
            plans: [],
            selected: '',
        }
    },
    mounted() {
        this.fetchPlans()
    },
    methods: {
        fetchPlans(loading) {
            http.get('broker_plans_widget')
                .then((response) => {
                    this.plans = response.data
                    if (this.brokerPlan)
                        this.selected = this.plans.filter(
                            (plan) => plan.id === this.brokerPlan
                        )[0]
                })
                .catch((error) => {
                    console.log(error)
                })
        },
    },
}
</script>

<style></style>
