<template>
    <div id="page-user-edit">
        <div
            v-if="brokerData"
            slot="no-body"
            class="tabs-container px-6 pt-6 vs-con-loading__container"
            id="div-with-loading"
        >
            <vs-tabs
                :position="isSmallerScreen ? 'top' : 'left'"
                class="tabs-shadow-none"
                id="profile-tabs"
                :key="isSmallerScreen"
            >
                <vs-tab
                    :label="!isSmallerScreen ? 'General' : ''"
                    icon-pack="feather"
                    icon="icon-info"
                >
                    <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
                        <vx-card no-shadow>
                            <div id="user-edit-tab-info">
                                <div class="vx-row">
                                    <div class="vx-col w-full md:w-1/2 mb-base">
                                        <!-- Col Header -->
                                        <div
                                            class="flex items-end md:mt-0 mt-base"
                                        >
                                            <feather-icon
                                                icon="HomeIcon"
                                                class="mr-2"
                                                svgClasses="w-5 h-5"
                                            />
                                            <span
                                                class="leading-none font-medium"
                                                >Account</span
                                            >
                                        </div>

                                        <!-- Account Content -->
                                        <div class="mb-base">
                                            <vs-input
                                                class="w-full mt-4"
                                                label="Company Name"
                                                v-model="
                                                    brokerData.company_name
                                                "
                                                name="company_name"
                                            />
                                            <vs-input
                                                class="w-full mt-4"
                                                label="Account Number"
                                                v-model="
                                                    brokerData.account_number
                                                "
                                                name="account_number"
                                            />
                                        </div>

                                        <!-- Col Header -->
                                        <div
                                            class="flex items-end md:mt-0 mt-base"
                                        >
                                            <feather-icon
                                                icon="PhoneIcon"
                                                class="mr-2"
                                                svgClasses="w-5 h-5"
                                            />
                                            <span
                                                class="leading-none font-medium"
                                                >Contact</span
                                            >
                                        </div>

                                        <!-- Contact Content -->
                                        <div>
                                            <vs-input
                                                class="w-full mt-4"
                                                label="Contact Name"
                                                v-model="
                                                    brokerData.contact_name
                                                "
                                                name="contact_name"
                                            />

                                            <vs-input
                                                class="w-full mt-4"
                                                label="Contact Number"
                                                v-model="
                                                    brokerData.contact_number
                                                "
                                                name="contact_number"
                                            />
                                            <vs-input
                                                class="w-full mt-4"
                                                label="Mobile Number"
                                                v-model="
                                                    brokerData.mobile_number
                                                "
                                                name="mobile_number"
                                            />
                                            <vs-input
                                                class="w-full mt-4"
                                                label="Contact Email"
                                                v-model="
                                                    brokerData.contact_email
                                                "
                                                name="contact_email"
                                            />
                                        </div>
                                    </div>

                                    <!-- Address Col -->
                                    <div class="vx-col w-full md:w-1/2 mb-base">
                                        <!-- Col Header -->
                                        <div
                                            class="flex items-end md:mt-0 mt-base"
                                        >
                                            <feather-icon
                                                icon="MapPinIcon"
                                                class="mr-2"
                                                svgClasses="w-5 h-5"
                                            />
                                            <span
                                                class="leading-none font-medium"
                                                >Address</span
                                            >
                                        </div>

                                        <!-- Col Content -->
                                        <div>
                                            <vs-input
                                                class="w-full mt-4"
                                                label="Address Line 1"
                                                v-model="
                                                    brokerData.address_line_1
                                                "
                                                name="address_line_1"
                                            />
                                            <vs-input
                                                class="w-full mt-4"
                                                label="Address Line 2"
                                                v-model="
                                                    brokerData.address_line_2
                                                "
                                            />
                                            <vs-input
                                                class="w-full mt-4"
                                                label="Post Town"
                                                v-model="brokerData.post_town"
                                            />
                                            <vs-input
                                                class="w-full mt-4"
                                                label="Post Code"
                                                v-model="brokerData.post_code"
                                            />
                                            <vs-input
                                                class="w-full mt-4"
                                                label="Country"
                                                v-model="brokerData.country"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="vx-row">
                                    <div class="flex flex-wrap items-center">
                                        <vs-button
                                            class="ml-auto mt-2"
                                            type="flat"
                                            color="primary"
                                            icon-pack="feather"
                                            icon="icon-chevron-left"
                                            v-on:click="back"
                                            >Back</vs-button
                                        >
                                        <vs-button
                                            class="bg-primary-gradient ml-4 mt-2"
                                            icon-pack="feather"
                                            icon="icon-save"
                                            v-on:click="save"
                                            >Save</vs-button
                                        >
                                    </div>
                                </div>
                            </div>
                        </vx-card>
                    </div>
                </vs-tab>
                <vs-tab
                    :label="!isSmallerScreen ? 'Logo' : ''"
                    icon-pack="feather"
                    icon="icon-image"
                >
                    <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
                        <vx-card no-shadow>
                            <!-- Col Header -->
                            <div class="flex items-end md:mt-0 mt-base mb-3">
                                <feather-icon
                                    icon="ImageIcon"
                                    class="mr-2"
                                    svgClasses="w-5 h-5"
                                />
                                <span class="leading-none font-medium"
                                    >Logo</span
                                >
                            </div>

                            <BtnImageSelect
                                :logo="brokerData.logo"
                                @logoChanged="brokerData.logo = $event"
                                :acceptFiles="'image/*'"
                            ></BtnImageSelect>
                            <div class="vx-row">
                                <div class="mt-8 flex flex-wrap items-center">
                                    <vs-button
                                        class="ml-auto mt-2"
                                        type="flat"
                                        color="primary"
                                        icon-pack="feather"
                                        icon="icon-chevron-left"
                                        v-on:click="back"
                                        >Back</vs-button
                                    >
                                    <vs-button
                                        class="bg-primary-gradient ml-4 mt-2"
                                        icon-pack="feather"
                                        icon="icon-save"
                                        v-on:click="save"
                                        >Save</vs-button
                                    >
                                </div>
                            </div>
                        </vx-card>
                    </div>
                </vs-tab>

                <vs-tab
                    :label="!isSmallerScreen ? 'Invoicing & GoCardless' : ''"
                    icon-pack="feather"
                    icon="icon-dollar-sign"
                >
                    <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
                        <vx-card no-shadow>
                            <!-- Col Header -->
                            <div class="flex items-end md:mt-0 mt-base mb-3">
                                <feather-icon
                                    icon="DollarSignIcon"
                                    class="mr-2"
                                    svgClasses="w-5 h-5"
                                />
                                <span class="leading-none font-medium"
                                    >Invoicing</span
                                >
                            </div>
                            <div class="vx-row mb-base">
                                <!-- Col Content -->
                                <div class="vx-col w-1/2">
                                    <BrokerPlanSelect
                                        :brokerPlan="brokerData.plan"
                                        @selectedPlan="setPlan"
                                    />
                                </div>
                            </div>
                            <div class="flex items-end md:mt-0 mt-base">
                                <feather-icon
                                    icon="DollarSignIcon"
                                    class="mr-2"
                                    svgClasses="w-5 h-5"
                                />
                                <span class="leading-none font-medium"
                                    >GoCardless</span
                                >
                            </div>
                            <div class="vx-row">
                                <!-- Col Content -->
                                <div class="vx-col w-1/2">
                                    <vs-input
                                        class="w-full mt-4"
                                        label="GoCardless Customer ID"
                                        v-model="
                                            brokerData.go_card_less_customer_id
                                        "
                                    />
                                </div>
                            </div>
                            <div class="vx-row">
                                <!-- Col Content -->
                                <div class="vx-col w-1/2">
                                    <vs-input
                                        class="w-full mt-4"
                                        label="GoCardless Mandate ID"
                                        v-model="
                                            brokerData.go_card_less_mandate_id
                                        "
                                    />
                                </div>
                            </div>

                            <div class="vx-row">
                                <div class="mt-8 flex flex-wrap items-center">
                                    <vs-button
                                        class="ml-auto mt-2"
                                        type="flat"
                                        color="primary"
                                        icon-pack="feather"
                                        icon="icon-chevron-left"
                                        v-on:click="back"
                                        >Back</vs-button
                                    >
                                    <vs-button
                                        class="bg-primary-gradient ml-4 mt-2"
                                        icon-pack="feather"
                                        icon="icon-save"
                                        v-on:click="save"
                                        >Save</vs-button
                                    >
                                </div>
                            </div>
                        </vx-card>
                    </div>
                </vs-tab>

                <vs-tab
                    :label="!isSmallerScreen ? 'Sending' : ''"
                    icon-pack="feather"
                    icon="icon-send"
                >
                    <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
                        <vx-card no-shadow>
                            <div id="user-edit-tab-info">
                                <div class="vx-row">
                                    <div class="vx-col w-full md:w-1/2 mb-base">
                                        <!-- Col Header -->
                                        <div
                                            class="flex items-end md:mt-0 mt-base"
                                        >
                                            <feather-icon
                                                icon="SendIcon"
                                                class="mr-2"
                                                svgClasses="w-5 h-5"
                                            />
                                            <span
                                                class="leading-none font-medium"
                                                >Auto</span
                                            >
                                        </div>

                                        <div class="flex mt-8">
                                            <label for="" class="mr-auto"
                                                >Auto Send<br />
                                                <span
                                                    class="text-xs text-success"
                                                    >Disables automatic
                                                    invitations & reminders for
                                                    uploaded clients</span
                                                ></label
                                            >
                                            <vs-switch
                                                color="success"
                                                v-model="brokerData.auto_send"
                                                vs-icon="done"
                                            >
                                            </vs-switch>
                                        </div>
                                    </div>

                                    <div class="vx-col w-full md:w-1/2 mb-base">
                                        <!-- Col Header -->
                                        <div
                                            class="flex items-end md:mt-0 mt-base"
                                        >
                                            <feather-icon
                                                icon="MessageSquareIcon"
                                                class="mr-2"
                                                svgClasses="w-5 h-5"
                                            />
                                            <span
                                                class="leading-none font-medium"
                                                >SMS</span
                                            >
                                        </div>

                                        <vs-input
                                            class="w-full mt-4"
                                            label="SMS From"
                                            v-model="brokerData.text_from"
                                            name="smsFrom"
                                        />
                                    </div>
                                </div>
                                <div class="vx-row">
                                    <div class="flex flex-wrap items-center">
                                        <vs-button
                                            class="ml-auto mt-2"
                                            type="flat"
                                            color="primary"
                                            icon-pack="feather"
                                            icon="icon-chevron-left"
                                            v-on:click="back"
                                            >Back</vs-button
                                        >
                                        <vs-button
                                            class="bg-primary-gradient ml-4 mt-2"
                                            icon-pack="feather"
                                            icon="icon-save"
                                            v-on:click="save"
                                            >Save</vs-button
                                        >
                                    </div>
                                </div>
                            </div>
                        </vx-card>
                    </div>
                </vs-tab>

                <vs-tab
                    :label="!isSmallerScreen ? 'Status' : ''"
                    icon-pack="feather"
                    icon="icon-tag"
                >
                    <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
                        <vx-card no-shadow>
                            <div id="user-edit-tab-info">
                                <div class="vx-row">
                                    <div class="vx-col w-full md:w-1/2 mb-base">
                                        <!-- Col Header -->
                                        <div
                                            class="flex items-end md:mt-0 mt-base"
                                        >
                                            <feather-icon
                                                icon="TagIcon"
                                                class="mr-2"
                                                svgClasses="w-5 h-5"
                                            />
                                            <span
                                                class="leading-none font-medium"
                                                >Setup Status</span
                                            >
                                        </div>

                                        <div class="mb-base">
                                            <div class="flex mt-8">
                                                <label for="" class="mr-auto"
                                                    >Contract Start Date</label
                                                >
                                                <datepicker
                                                    v-model="startDate"
                                                    label="Start Date"
                                                />
                                            </div>
                                            <div class="flex mt-8">
                                                <label for="" class="mr-auto"
                                                    >Account Active<br />
                                                    <span
                                                        class="text-xs text-success"
                                                        >Disables invitations &
                                                        reminders</span
                                                    ></label
                                                >
                                                <vs-switch
                                                    color="success"
                                                    v-model="brokerData.active"
                                                    vs-icon="done"
                                                >
                                                </vs-switch>
                                            </div>
                                            <div class="flex mt-8">
                                                <label for="" class="mr-auto"
                                                    >Staff Training</label
                                                >
                                                <vs-switch
                                                    color="success"
                                                    v-model="
                                                        brokerData.staff_training_status
                                                    "
                                                    vs-icon="done"
                                                >
                                                </vs-switch>
                                            </div>
                                            <div class="flex mt-8">
                                                <label for="" class="mr-auto"
                                                    >Introducer Agreement</label
                                                >
                                                <vs-switch
                                                    color="success"
                                                    v-model="
                                                        brokerData.introducer_agreement_status
                                                    "
                                                    vs-icon="done"
                                                >
                                                </vs-switch>
                                            </div>
                                            <div class="flex mt-8">
                                                <label for="" class="mr-auto"
                                                    >Vouchers</label
                                                >
                                                <vs-switch
                                                    color="success"
                                                    v-model="
                                                        brokerData.vouchers_status
                                                    "
                                                    vs-icon="done"
                                                >
                                                </vs-switch>
                                            </div>
                                            <div class="flex mt-8">
                                                <label for="" class="mr-auto"
                                                    >Direct Debit</label
                                                >
                                                <vs-switch
                                                    color="success"
                                                    v-model="
                                                        brokerData.direct_debit_status
                                                    "
                                                    vs-icon="done"
                                                >
                                                </vs-switch>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="vx-row">
                                    <div class="flex flex-wrap items-center">
                                        <vs-button
                                            class="ml-auto mt-2"
                                            type="flat"
                                            color="primary"
                                            icon-pack="feather"
                                            icon="icon-chevron-left"
                                            v-on:click="back"
                                            >Back</vs-button
                                        >
                                        <vs-button
                                            class="bg-primary-gradient ml-4 mt-2"
                                            icon-pack="feather"
                                            icon="icon-save"
                                            v-on:click="save"
                                            >Save</vs-button
                                        >
                                    </div>
                                </div>
                            </div>
                        </vx-card>
                    </div>
                </vs-tab>
                <vs-tab
                    :label="!isSmallerScreen ? 'Notifications' : ''"
                    icon-pack="feather"
                    icon="icon-info"
                >
                    <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
                        <vx-card no-shadow>
                            <div id="user-edit-tab-info">
                                <div class="vx-row">
                                    <div class="vx-col w-full md:w-1/2 mb-base">
                                        <!-- Col Header -->
                                        <div
                                            class="flex items-end md:mt-0 mt-base"
                                        >
                                            <feather-icon
                                                icon="HomeIcon"
                                                class="mr-2"
                                                svgClasses="w-5 h-5"
                                            />
                                            <span
                                                class="leading-none font-medium"
                                                >Notifications</span
                                            >
                                        </div>

                                        <div class="mb-base">
                                            <div class="flex mt-8">
                                                <label for="" class="mr-auto"
                                                    >Send Will completed email
                                                </label>
                                                <vs-switch
                                                    color="success"
                                                    v-model="
                                                        brokerData.completed_wills
                                                    "
                                                    vs-icon="done"
                                                >
                                                </vs-switch>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="vx-row">
                                    <div class="flex flex-wrap items-center">
                                        <vs-button
                                            class="ml-auto mt-2"
                                            type="flat"
                                            color="primary"
                                            icon-pack="feather"
                                            icon="icon-chevron-left"
                                            v-on:click="back"
                                            >Back</vs-button
                                        >
                                        <vs-button
                                            class="bg-primary-gradient ml-4 mt-2"
                                            icon-pack="feather"
                                            icon="icon-save"
                                            v-on:click="save"
                                            >Save</vs-button
                                        >
                                    </div>
                                </div>
                            </div>
                        </vx-card>
                    </div>
                </vs-tab>
                <vs-tab
                    :label="!isSmallerScreen ? 'Primis' : ''"
                    icon-pack="feather"
                    icon="icon-info"
                >
                    <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
                        <vx-card no-shadow>
                            <div id="user-edit-tab-info">
                                <div class="vx-row">
                                    <div class="vx-col w-full md:w-1/2 mb-base">
                                        <!-- Col Header -->
                                        <div
                                            class="flex items-end md:mt-0 mt-base"
                                        >
                                            <feather-icon
                                                icon="HomeIcon"
                                                class="mr-2"
                                                svgClasses="w-5 h-5"
                                            />
                                            <span
                                                class="leading-none font-medium"
                                                >Account</span
                                            >
                                        </div>

                                        <!-- Primis id -->
                                        <div class="mb-base mt-4">
                                            <label class="vs-input--label"
                                                >Primis Ids</label
                                            >
                                            <p
                                                v-for="(primis,
                                                index) in brokerData.primis_ids"
                                                :key="index"
                                                class="mt-4 flex justify-between"
                                            >
                                                <span>{{
                                                    primis.primis_id
                                                }}</span>
                                                <vs-button
                                                    @click="
                                                        deletePrimisId(primis)
                                                    "
                                                    icon-pack="feather"
                                                    icon="icon-trash"
                                                    color="danger"
                                                    class="mr-3"
                                                >
                                                </vs-button>
                                            </p>

                                            <p
                                                v-if="
                                                    !brokerData.primis_ids
                                                        .length
                                                "
                                                class="text-sm mt-3"
                                            >
                                                No Primis Ids added
                                            </p>
                                        </div>

                                        <!-- Primis report include keywords -->
                                        <div class="mb-base">
                                            <vs-input
                                                class="w-full mt-4"
                                                label="Primis Keywords"
                                                description-text="Keywords to include on Primis Toolbox report for upload"
                                                v-model="
                                                    brokerData.primis_report_keywords_include
                                                "
                                                name="primis_id"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="vx-row">
                                    <div class="flex flex-wrap items-center">
                                        <vs-button
                                            class="ml-auto mt-2"
                                            type="flat"
                                            color="primary"
                                            icon-pack="feather"
                                            icon="icon-chevron-left"
                                            v-on:click="back"
                                            >Back</vs-button
                                        >
                                        <vs-button
                                            class="bg-primary-gradient ml-4 mt-2"
                                            icon-pack="feather"
                                            icon="icon-save"
                                            v-on:click="save"
                                            >Save</vs-button
                                        >
                                    </div>
                                </div>
                            </div>
                        </vx-card>
                    </div>
                </vs-tab>
            </vs-tabs>
        </div>
        <!-- Save & Reset Button -->
        <div class="vx-row">
            <div class="vx-col w-full">
                <div class="mt-8 flex flex-wrap items-center justify-end"></div>
            </div>
        </div>
    </div>
</template>
]

<script>
import { http, httpGoCardless } from '@/services'
import Datepicker from 'vuejs-datepicker'

import BtnImageSelect from '@/components/common/BtnImageSelect'
import BrokerPlanSelect from '@/components/common/BrokerPlanSelect'

export default {
    name: 'BrokerEdit',
    props: ['data'],
    components: {
        BtnImageSelect,
        BrokerPlanSelect,
        Datepicker,
    },
    created() {
        this.brokerDataFetch(this.$route.params.pk)
    },
    beforeRouteUpdate(to, from, next) {
        next()
        this.brokerDataFetch(this.$route.params.pk)
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.backPath = from.path
        })
    },
    data() {
        return {
            brokerData: null,
            backPath: null,
            activeTab: 0,
            form: {
                primisId: null,
            },
        }
    },
    computed: {
        isSmallerScreen() {
            return this.$store.state.windowWidth < 768
        },
        startDate: {
            get() {
                if (this.brokerData) return this.brokerData.start_date
                return null
            },
            set(value) {
                this.brokerData.start_date = value.toISOString()
            },
        },
    },
    methods: {
        brokerDataFetch(broker_pk) {
            this.$vs.loading()
            http.get('broker_detail', { params: { pk: broker_pk } })
                .then((response) => {
                    this.brokerData = response.data
                    this.$vs.loading.close()
                })
                .catch((error) => {
                    this.$vs.loading.close()
                })
        },
        save() {
            this.$vs.loading({
                container: '#div-with-loading',
                scale: 0.6,
            })
            var formData = new FormData()

            delete this.brokerData.user
            if (typeof this.brokerData.logo === 'string')
                delete this.brokerData.logo

            for (const property in this.brokerData) {
                if (this.brokerData[property] !== null)
                    formData.append(
                        property.toString(),
                        this.brokerData[property]
                    )
            }

            http.patch('broker_detail', formData)
                .then((resoponse) => {
                    this.$vs.notify({
                        text: 'Broker data saved',
                        color: 'success',
                    })
                    this.back()
                    this.$vs.loading.close(
                        '#div-with-loading > .con-vs-loading'
                    )
                })
                .catch((error) => {
                    this.$vs.notify({
                        text: error.response.data,
                        color: 'danger',
                    })
                    this.$vs.loading.close(
                        '#div-with-loading > .con-vs-loading'
                    )
                })
        },
        back() {
            if (this.backPath) this.$router.push(this.backPath)
            else this.$router.push('/brokers')
        },
        setPlan(value) {
            console.log('set plan', value)
            if (value) this.brokerData.plan = value.id
            else this.brokerData.plan = null
        },
        handleFileChange(value) {
            this.brokerData.logo = value.target.files[0]
        },
        deletePrimisId(primis) {
            http.delete('broker/primis_id', {
                params: { primis_id: primis.primis_id },
            })
                .then(() => {
                    this.$vs.notify({
                        text: 'Primis ID Deleted',
                        color: 'success',
                    })
                    this.brokerDataFetch(this.$route.params.pk)
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        addPrimisId() {
            http.post('broker/primis_id', {
                broker_id: this.brokerData.id,
                primis_id: this.form.primisId,
            })
                .then(() => {
                    this.$vs.notify({
                        text: 'Primis ID Added',
                        color: 'success',
                    })
                    this.brokerDataFetch(this.$route.params.pk)
                })
                .catch((error) => {
                    console.log(error)
                })
        },
    },
}
</script>

<style lang="scss">
#avatar-col {
    width: 10rem;
}
.image {
    height: 300px;
}
#page-user-view {
    table {
        td {
            vertical-align: top;
            min-width: 140px;
            padding-bottom: 0.8rem;
            word-break: break-all;
        }

        &:not(.permissions-table) {
            td {
                @media screen and (max-width: 370px) {
                    display: block;
                }
            }
        }
    }
}

@media screen and (min-width: 1201px) and (max-width: 1211px),
    only screen and (min-width: 636px) and (max-width: 991px) {
    #account-info-col-1 {
        width: calc(100% - 12rem) !important;
    }
}
</style>
