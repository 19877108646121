<template>
    <div>
        <div class="img-container">
            <img v-if="logo" class="image mb-6 w-full" :src="getImage" />
        </div>

        <vs-button v-on:click="selectNewFile" :color="'success'" type="flat">
            <div class="btn btn--outline btn--upload">
                <span v-html="logoBtnText"
                    ><br /><span v-if="logo" class="file-size"
                        >{{ logo.size / 1000 }}Kb</span
                    ></span
                >
            </div>
        </vs-button>

        <input
            ref="fileInput"
            type="file"
            @change="handleFileChange"
            v-bind:accept="acceptFiles"
        />
    </div>
</template>

<script>
export default {
    name: 'BtnImageSelect',
    props: ['logo', 'acceptFiles'],
    computed: {
        getImage() {
            if (this.logo && this.logo instanceof File) {
                var url = URL.createObjectURL(this.logo)
                return url
            }
            return this.logo
        },
        logoBtnText() {
            if (this.logo && this.logo instanceof File) {
                return `${this.logo.name} <br><span class="file-size">${(
                    this.logo.size / 1000000
                ).toFixed(3)} Mb </span>`
            } else if (this.logo && typeof this.logo === 'string')
                return 'Change Existing'
            else return 'Add'
        },
    },
    methods: {
        handleFileChange(e) {
            this.$emit('logoChanged', e.target.files[0])
            e.target.value = ''
        },
        selectNewFile() {
            this.$refs.fileInput.click()
        },
    },
}
</script>

<style scoped>
input[type='file'] {
    display: none;
}
.file-size {
    font-size: 0.7em;
}
.image {
    border-radius: 10px;
    max-width: 500px;
    height: auto;
}
</style>
